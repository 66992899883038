
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import isEnabled from "@/composables/isEnabled.js";

import services from "../../../axios/dbManag";

import { useRedirectInsegnanti } from "@/composables/redirectInsegnanti";

import Swal from "sweetalert2/dist/sweetalert2.min.js";

// import ModalEsportaSanzioni from "@/components/components-fit/persone/ModalEsportaSanzioni.vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

export default defineComponent({
  name: "storico-persona",
  components: {
    //ModalEsportaSanzioni,
    Datatable,
  },
  props: ["persona"],
  setup(props) {
    const sanzioniPersona = ref<any[]>([]);
    const errore = ref(false);
    const loaded = ref(false);
    const status = ref(0);

    services.dbManag
      .post(`/anagrafiche/persone/sanzioni/list`, {
        id_persona: props.persona.id,
      })
      .then((res) => {
        status.value = res.status;
        if (res.data.results) {
          res.data.results.forEach((storico) => {
            sanzioniPersona.value.push(storico);
          });
        }
        loaded.value = true;
      })
      .catch((e) => {
        errore.value = true;
        loaded.value = true;
      });
    //funzione gestione anno
    const getArrayLastYear = (arrayToFilter, lastYear) => {
      const array = arrayToFilter.filter((item) => {
        return item.stagione === lastYear;
      });
      return array;
    };
    const getArrayOtherYears = (arrayToFilter, lastYear) => {
      const array = arrayToFilter.filter((item) => {
        if (item.stagione != lastYear) {
          console.log(item);
        }
        return item.stagione != lastYear;
      });
      return array;
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Sanzioni Persona", [
        "Lista Persone",
        "Dettaglio Persona",
      ]);
    });

    const { pagesLength, redirectInsegnanti, menuLoaded, hasBacheca } =
      useRedirectInsegnanti();

    const tableHeader = ref([
      {
        name: "Stagione",
        key: "stagione",
        sortable: false,
      },
      {
        name: "Sanzione",
        key: "sanzione",
        sortable: false,
      },
      {
        name: "Numero sentenza",
        key: "numero_sentenza",
        sortable: false,
      },
      {
        name: "Numero procedimento",
        key: "num_proc",
        sortable: false,
      },
      {
        name: "Data da",
        key: "data_da",
        sortable: false,
      },
      {
        name: "Data a",
        key: "data_a",
        sortable: false,
      },
      {
        name: "Data decorrenza",
        key: "data_decorrenza",
        sortable: false,
      },
      {
        name: "Numero giorni",
        key: "numero_giorni",
        sortable: false,
      },
      {
        name: "Stato pagamento",
        key: "stato_pagamento",
        sortable: false,
      },
    ]);

    return {
      sanzioniPersona,
      getArrayLastYear,
      getArrayOtherYears,
      loaded,
      errore,
      isEnabled,
      pagesLength,
      redirectInsegnanti,
      menuLoaded,
      hasBacheca,
      status,
      tableHeader,
    };
  },
});
